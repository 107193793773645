.token.whitespace {
  position: relative;
}

.token.whitespace::after {
  position: absolute;
  left: 0;
  top: 0;
  color: #585858;
  content: "·";
}

.token.bracket {
  color: #f05151;
}
